import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

export default {
	name: "csiiLifestyle",
	data() {
		return {
			token: '',
			patientUuid: '',
			show: false,
			date: '',
			minDate: new Date(2018, 0, 1),

			csiiLifeMedicalForm: {
				weight:Number,
				visitDate: '',
				hospitalName: '',
				hospitalCode: '',
				testReportResult1: '',
				testReportResult2: '',
				testReportResult3: '',
				testReportResult4: '',
				testReportResult5: '',
				testReportResult6: '',
				testReportResult7: '',
				testReportResult8: '',
				testReportResult9: '',
				testReportResult10: '',
				testReportResult11: '',
				testReportResult12: '',
				testReportResult13: '',
				testReportResult14: ''
				}
			}
		},
		mounted() {
				this.token = this.$route.query.token;
				this.patientUuid = this.$route.query.patientUuid;
			},
			methods: {

			}
	}
